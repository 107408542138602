import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import Logo from "../../components/Logo/Logo";

import './TechWorksPage.scss';

class TechWorksPage extends Component {
  render() {
    return (
      <div className="techWorksWrap">
        <Logo />
        <div className="descWrap">
          <h1>Технічне обслуговування сайту</h1>
          <p>Зараз ведуться роботи, спрямовані на підвищення стабільності та швидкості роботи нашого сайту</p>
        </div>
        <p>Повернемось дуже скоро!</p>
      </div>
    );
  }
}

const techWorksPageTranslation = withTranslation()(TechWorksPage);

export default techWorksPageTranslation;