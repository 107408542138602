import React, { Component } from 'react';
import SmartBanner from 'react-smartbanner';
import { withRouter } from 'react-router-dom';
import 'react-smartbanner/dist/main.css';

import './Header.scss';
import Logo from '../Logo/Logo';
import NavigationItems from './Navigation/NavigationItems/NavigationItems';
import DrawerToggle from './Navigation/SideDrawer/DrawerToggle/DrawerToggle';
import LanguageDropdown from '../UI/LanguageDropdown/LanguageDropdown';

class Header extends Component {
  state = {
    width: 0
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  selectLanguage = (lang) => {
    this.props.onChangeLang(lang);
  };

  render() {
    const { width } = this.state;
    const { languages, currentLang, t, history } = this.props;
    const { location } = history;
    const { pathname } = location;

    return (
      <header>
        {
          (pathname !== '/bonusnaya-sistema-lyubimyj-gost' && width <= 1024) ?
            <SmartBanner title="G-Group Restaurant Company"
              button={'Скачать'}
              storeText={{ ios: 'g-group.com.ua', android: 'g-group.com.ua' }}
              url={{
                ios: 'https://apps.apple.com/ua/app/g-group-restaurant-company/id1467395909',
                android: 'https://play.google.com/store/apps/details?id=com.ggroup.gbsfo'
              }}
              onClose={(e) => {
                console.log(e)
              }}
            /> : null
        }

        {
          width > 1024 ?
            <div className="Wrapper">
              <div className="Left">
                <Logo />
                {!this.props.techWorks && <NavigationItems t={t}/>}
              </div>
              <div className="Right">
                {!this.props.techWorks &&
                  <LanguageDropdown
                  languages={languages}
                  currentLang={currentLang}
                  onSelectLanguage={this.selectLanguage}
                />
                }
              </div>
            </div> :
            <div className="Wrapper">
              <div className="Left">
                <Logo />
              </div>
              <div className="Right">
                {!this.props.techWorks &&
                  <>
                    <LanguageDropdown
                      languages={languages}
                      currentLang={currentLang}
                      onSelectLanguage={this.selectLanguage}
                    />
                    <DrawerToggle clicked={this.props.drawerToggleClicked}/>
                  </>
                }
              </div>
            </div>
        }
      </header>
    )
  }
};

export default withRouter(Header);